<template>
  <!-- 日志管理 -->
  <div class="box-card" style="text-align: left;margin-top: 20px;">
    <!-- 查询表单 -->
    <el-form :inline="true" size="small" label-position="right" class="query_form" :model="queryForm"
      @submit.native.prevent>
      <el-form-item label="时间：">
        <el-date-picker v-model="timeduan" value-format="yyyy-MM-dd HH:mm:ss" :default-time="['00:00:00', '23:59:59']"
          @blur="onSubmit" type="datetimerange" range-separator="至" start-placeholder="请选择开始日期" end-placeholder="请选择结束日期">
        </el-date-picker>
        <!-- <el-date-picker v-model="queryForm.startTime" type="datetime" placeholder="开始时间">
            </el-date-picker>
            <span> ~ </span>
            <el-date-picker v-model="queryForm.endTime" type="datetime" placeholder="结束时间">
            </el-date-picker> -->
      </el-form-item>
      <el-form-item label="用户名：">
        <el-input maxlength="15" :value="queryForm.create_name" placeholder="请输入用户名"
          @input="(e) => (queryForm.create_name = validSpace(e))" @keyup.enter.native="onSubmit"></el-input>
      </el-form-item>
      <el-form-item label="操作内容：">
        <el-input maxlength="15" :value="queryForm.content" placeholder="请输入操作内容"
          @input="(e) => (queryForm.content = validSpace(e))" @keyup.enter.native="onSubmit"></el-input>
      </el-form-item>

      <el-form-item>
        <lbButton icon="chaxun" :fill="true" @click="onSubmit()">查询</lbButton>
      </el-form-item>
    </el-form>
    <le-card title="日志管理">
      <!-- 主要内容 -->
      <div>
        <!-- 表格 -->
         <!-- 使用插件生产表格 -->
         <!-- <avue-crud :data="tableData" :option="option" :page.sync="page"
          @size-change="sizeChange" @current-change="currentChange">
           <template slot-scope="{row}" slot="group_name">
            <span>{{row.group_name &&row.group_name != '000000000000000000000000'
                ?row.group_name : '湖南乐泊科技有限公司' }}</span>
          </template>
           <template slot-scope="{row}" slot="operate_type">
            <span>{{ getWayName("OperationType", row.operate_type) }}</span>
          </template>
          <template slot-scope="{row}" slot="create_time">
            <span>{{ validDateTime(row.create_time) }}</span>
          </template>
        </avue-crud> -->
        <el-table class="tablestyle" ref="multipleTable" :data="tableData" align="center" header-align="center"
          tooltip-effect="dark" border stripe>
          <el-table-column prop="create_name" label="用户名"></el-table-column>
          <el-table-column label="集团" min-width="120">
            <template slot-scope="scope">
              <span>{{ scope.row.group_name && scope.row.group_name != '000000000000000000000000'
                ? scope.row.group_name : '湖南乐泊科技有限公司' }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="menu_name" label="版块"></el-table-column>
          <el-table-column label="操作类型">
            <template slot-scope="scope">
              <span>{{ getWayName("OperationType", scope.row.operate_type) }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="content" label="操作内容" min-width="160"></el-table-column>
          <el-table-column label="操作时间">
            <template slot-scope="scope">
              <span>{{ validDateTime(scope.row.create_time) }}</span>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="queryForm.PageIndex" :page-sizes="[10, 20, 30, 40]" :page-size="queryForm.PageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="TotalCount"></el-pagination>
      </div>
    </le-card>
  </div>
</template>
<script>
import request from '@/api/systemapi'
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      // 查询表单
      queryForm: {
        startTime: '',
        endTime: '',
        create_name: '',
        content: '',
        PageSize: 10,
        PageIndex: 1
      },
      timeduan: '',
      // 表格数据
      tableData: [],
      TotalCount: 0,
      // 表格插件配置项
      option: {
        searchMenuSpan: 8,
        header: false,
        delBtn: false, // 行删除按钮
        editBtn: false, // 编辑按钮
        menuAlign: 'left', // 属性对齐方式
        menuHeaderAlign: 'left', // 表头对齐方式
        menu: false,
        menuFixed: false, // 解除操作框冻结
        tip: false,
        column: [
          {
            label: '用户名',
            prop: 'create_name'
          }, {
            label: '集团',
            prop: 'group_name',
            slot: true
          }, {
            label: '版块',
            prop: 'menu_name'
          }, {
            label: '操作类型',
            prop: 'operate_type',
            slot: true
          }, {
            label: '操作内容',
            prop: 'content'
          }, {
            label: '操作时间',
            prop: 'create_time',
            slot: true
          }
        ]
      },
      // 分页器
      page: {
        background: false,
        total: 0,
        pageSize: 10,
        currentPage: 1
      }
    }
  },
  watch: {
    'timeduan' (newV, oldV) {
      if (newV) {
        this.queryForm.startTime = newV[0]
        this.queryForm.endTime = newV[1]
      } else {
        this.queryForm.startTime = ''
        this.queryForm.endTime = ''
        this.onSubmit()
      }
    }
    // "queryForm.endTime"() {
    //   if (this.queryForm.endTime && this.queryForm.startTime) {
    //     this.comparaDataTime(this.queryForm.startTime, this.queryForm.endTime);
    //   }
    // },
    // "queryForm.startTime"() {
    //   if (this.queryForm.startTime && this.queryForm.endTime) {
    //     this.comparaDataTime(this.queryForm.startTime, this.queryForm.endTime);
    //   }
    // },
  },
  created () { },
  mounted () {
    this.requestGetLogList()
  },
  computed: {
    ...mapGetters(['getWayName', 'getOptions'])
  },
  methods: {
    // 查询
    onSubmit () {
      this.queryForm.PageIndex = 1
      this.requestGetLogList()
    },
    // 每页条数改变
    handleSizeChange (size) {
      this.queryForm.PageSize = size
      this.requestGetLogList()
    },
    // // 每页条数改变
    // sizeChange (val) {
    //   this.page.currentPage = 1
    //   this.page.pageSize = val
    //   this.queryForm.PageSize = val
    //   this.requestGetLogList()
    // },
    // 页码改变
    handleCurrentChange (page) {
      this.queryForm.PageIndex = page
      this.requestGetLogList()
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0
    },
    // // 页码改变
    // currentChange (val) {
    //   this.queryForm.PageIndex = val
    //   this.page.currentPage = val
    //   this.requestGetLogList()
    // },
    find () {
      this.$router.push('/order-info-look')
    },
    async requestGetLogList () {
      const res = await request.getLogList(this.queryForm)
      console.log(res)
      if (res && res.Code === 200) {
        this.tableData = res.Data.DataList
        this.TotalCount = res.Data.TotalCount
      }
      this.page.total = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
    }
  }
}
</script>

<style scoped lang="less">
.button_box {
  display: flex;
}

.button_box .el-button {
  margin-left: 15px;
  width: auto;
}

.el-dialog__body {
  padding: 20px;
}

.el-button {
  width: auto !important;
}</style>
